import { Company, CurrencyCode, MeasuredBy } from "@moodys/cre-cpm-client-apis.apis.services.auth"
import loanSensitivityRanges from "constants/loan-sensitivity"
import { Languages } from "constants/shared"

const initialCompany: Company = Object.freeze({
  id: "",
  type: "companies",
  attributes: {
    avatar_url: "",
    cmm_organization_id: undefined,
    company_ref_uuid: undefined,
    created_at: -1,
    currency_code: "EUR" as CurrencyCode,
    description: "",
    exportable_asset_fields: [],
    exportable_unit_fields: [],
    external_company_id: undefined,
    external_users_count: 0,
    external_users_limit: 0,
    feature_access: [],
    image_url: "",
    internal_users_count: 0,
    internal_users_limit: 0,
    invitation_template_id: "",
    language: Languages.english,
    layouts: null,
    loan_sensitivity_settings: loanSensitivityRanges,
    loans_kpi_layout: null,
    mappable_asset_fields: [],
    mappable_unit_fields: [],
    mark_logo_url: "",
    measured_by: "m2" as MeasuredBy,
    name: "",
    quiqproperty_public_key: undefined,
    quiqproperty_secret_key: undefined,
    region: "",
    style: null,
    sub_domain: "",
    timeout: 0,
    updated_at: -1,
    website_url: ""
  }
})

export default initialCompany
