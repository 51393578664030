import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  Affix,
  Button,
  Checkbox,
  Col,
  DatePicker,
  FormInstance,
  Input,
  Row,
  Select,
  Spin
} from "antd"
import moment from "moment"
import { DefaultOptionType } from "antd/lib/select"
import {
  Company,
  FeatureAccess,
  User,
  UserType
} from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { Portfolio } from "@moodys/cre-cpm-client-apis.apis.services.platform"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import FormCard from "components/Admin/FormCard"
import RequireAuth from "components/Admin/RequireAuth"
import ThinFormItem from "components/Antd/ThinFormItem"
import { Spacing, StoreKeysList } from "constants/shared"
import { AppContext } from "contexts/AppContext"
import { AuthContext } from "contexts/AuthContext"
import { PlatformContext } from "contexts/PlatformContext"
import { UserForm } from "models/user-form.model"
import { useQuery } from "@moodys/cre-cpm.hooks.use-query"
import { PortfolioStoreContext } from "contexts/StoreProvider"
import { dateLocaleFormats } from "@moodys/cre-cpm.constants.date-and-time"
import disabledPastDate from "functions/Dates"
import BasicUserForm from "components/Forms/BasicUserForm"
import handleApiError from "functions/Exceptions"
import getPortfoliosOptions, { AllKey } from "./function"

const { Option } = Select

interface LeftFormProps {
  loading: boolean
  user?: User
  id?: string
  saving: boolean
  form: FormInstance<UserForm>
  selectedCompany?: Company
  companyDataSource?: Company[]
  userFeatureAccess?: FeatureAccess[]
  onCompanySearch: (value: string) => void
  onCompanyChange: (companyUuid: string) => void
  onSendInviteOrResetPassword: () => void
  onRoleSelected: (role: UserType) => void
}

const LeftForm: React.FC<LeftFormProps> = ({
  loading,
  user,
  id,
  saving,
  form,
  selectedCompany,
  companyDataSource,
  userFeatureAccess,
  onCompanySearch,
  onCompanyChange,
  onSendInviteOrResetPassword,
  onRoleSelected
}) => {
  const { t } = useTranslation("auth")
  const { isCompanyAdmin, userType, isSuperAdmin } = useContext(AuthContext)
  const { locale } = useContext(AppContext)
  const { getPortfolios } = useContext(PlatformContext)
  const [portfolios, isLoadingPortfolios, , , requestPortfolios] = useQuery<Portfolio[]>(
    () =>
      getPortfolios({ company_ref_uuid: selectedCompany?.id ?? "" }).then(
        (result) => result ?? undefined
      ),
    {
      cacheKey: StoreKeysList.portfolios,
      disabled: true,
      storeContext: PortfolioStoreContext,
      onError: (err) => {
        handleApiError(err)
      }
    }
  )
  const [disableExternalFields, setDisableExternalFields] = useState<boolean | undefined>()
  const [selectedPotfolios, setSelectedPortfolios] = useState<string[] | undefined>(
    user?.attributes?.portfolios
  )

  const onUserTypeChange = (value: UserType) => {
    setDisableExternalFields(value !== "external_user")
    onRoleSelected(value)
  }

  const isDisableExternalFields = (): boolean => {
    const initialValue = user?.attributes?.user_type
    return disableExternalFields ?? initialValue !== "external_user"
  }

  const onPortfoliosChange = (
    values: string[],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _option: DefaultOptionType | DefaultOptionType[]
  ) => {
    if (values.length === 2 && values[0] === AllKey) {
      setSelectedPortfolios(values.filter((portfolioKey) => portfolioKey !== AllKey))
    } else if (values.includes(AllKey)) {
      setSelectedPortfolios([AllKey])
    } else {
      setSelectedPortfolios(values)
    }
  }

  useEffect(() => {
    form.setFieldsValue({
      company_uuid: selectedCompany?.id
    })
    // eslint-disable-next-line
  }, [selectedCompany])

  useEffect(() => {
    form.setFieldsValue({
      portfolios: selectedPotfolios
    })

    // eslint-disable-next-line
  }, [selectedPotfolios])

  useEffect(() => {
    const initialPortfolios = user?.attributes?.portfolios
    setSelectedPortfolios(
      !initialPortfolios || !initialPortfolios.length ? [AllKey] : initialPortfolios
    )

    // eslint-disable-next-line
  }, [user?.attributes?.portfolios])

  useEffect(() => {
    if (disableExternalFields) {
      form.setFieldsValue({
        expiration_date: undefined,
        external_organization: null
      })
    }
  }, [disableExternalFields])

  useEffect(() => {
    if ((isCompanyAdmin() || isSuperAdmin()) && selectedCompany) {
      requestPortfolios()
    }
  }, [selectedCompany])

  const mainForm = () => (
    <FormCard subtitle={t("users.info")} title={t("shared.admin_console")}>
      <BasicUserForm
        user={user}
        id={id}
        sendInviteOrResetPasswordButton={
          <Button
            onClick={onSendInviteOrResetPassword}
            type="primary"
            data-testid={getTestId("user_leftform__send_invite_reset_btn")}
          >
            {user?.attributes?.active ? t("users.reset_password") : t("users.resend_invitation")}
          </Button>
        }
        lockUserCheckbox={<Checkbox data-testid={getTestId("user_leftform__locked_at_checkbox")} />}
        form={form}
        userFeatureAccess={userFeatureAccess}
      />
      <RequireAuth>
        <Row>
          <Col span={23}>
            <ThinFormItem
              name="company_uuid"
              rules={[
                { required: true, message: t("companies.errors.search_by_company_name.required") }
              ]}
              label={t("shared.company")}
              initialValue={selectedCompany}
            >
              <Select
                allowClear
                showSearch
                placeholder={t("shared.search_by_company_name")}
                defaultActiveFirstOption={false}
                filterOption={false}
                onSearch={onCompanySearch}
                onChange={onCompanyChange}
                data-testid={getTestId("user_leftform__search_company_dropdown")}
                disabled={!isSuperAdmin()}
              >
                {companyDataSource?.map((company) => (
                  <Option key={company.id} value={company.id}>
                    {company.attributes.name}
                  </Option>
                ))}
              </Select>
            </ThinFormItem>
          </Col>
        </Row>
      </RequireAuth>
      <Row>
        <Col span={23}>
          <ThinFormItem
            name="user_type"
            label={t("users.user_type")}
            rules={[{ required: true, message: t("users.errors.user_type.required") }]}
            initialValue={user?.attributes?.user_type}
          >
            <Select
              onChange={onUserTypeChange}
              data-testid={getTestId("user_leftform_roles_dropdown")}
            >
              {isSuperAdmin() && (
                <Option value="superadmin">{t("shared.roles.rxd_admin.text")}</Option>
              )}
              <Option value="company_admin">{t("shared.roles.company_admin.text")}</Option>
              <Option value="company_user">{t("shared.roles.internal.text")}</Option>
              <Option value="external_user">{t("shared.roles.external.text")}</Option>
            </Select>
          </ThinFormItem>
        </Col>
      </Row>
      <Row>
        <Col span={11}>
          <ThinFormItem
            name="expiration_date"
            label={t("users.expiration_date")}
            initialValue={
              user?.attributes?.expiration_date
                ? moment.unix(user?.attributes?.expiration_date)
                : undefined
            }
            rules={[
              {
                required: !isDisableExternalFields(),
                message: t("users.errors.expiration_date.required")
              }
            ]}
          >
            <DatePicker
              format={dateLocaleFormats[locale]}
              disabled={isDisableExternalFields()}
              disabledDate={disabledPastDate}
              data-testid={getTestId("user_leftform__datepicker_expiration")}
            />
          </ThinFormItem>
        </Col>
        <Col span={11} offset={1}>
          <ThinFormItem
            name="external_organization"
            label={t("users.external_organization")}
            initialValue={user?.attributes?.external_organization}
            rules={[
              {
                required: !isDisableExternalFields(),
                message: t("users.errors.external_organization.required")
              }
            ]}
          >
            <Input
              disabled={isDisableExternalFields()}
              data-testid={getTestId("user_leftform__external_company_input")}
            />
          </ThinFormItem>
        </Col>
      </Row>
      {(userType === "company_admin" || userType === "superadmin") && (
        <Row>
          <Col span={23}>
            <ThinFormItem
              name="portfolios"
              label={t("users.portfolios")}
              rules={[
                () => ({
                  validator(_, value) {
                    if (value && value.length) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error(t("users.errors.portfolios.required")))
                  }
                })
              ]}
            >
              <Select
                mode="multiple"
                optionFilterProp="children"
                showSearch
                value={selectedPotfolios}
                onChange={onPortfoliosChange}
                data-testid={getTestId("user_leftform__portfolios_dropdown")}
                filterSort={(firstPortfolio, secondPortfolio) => {
                  if (firstPortfolio.value === AllKey) return -1
                  if (secondPortfolio.value === AllKey) return 1
                  return (firstPortfolio.children?.toString() ?? "")
                    .toLowerCase()
                    .localeCompare((secondPortfolio.children?.toString() ?? "").toLowerCase())
                }}
              >
                {getPortfoliosOptions(portfolios)}
              </Select>
            </ThinFormItem>
          </Col>
        </Row>
      )}
      <Row justify="end">
        <Affix style={{ position: "absolute", bottom: Spacing.s16px, right: Spacing.s16px }}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={saving}
            loading={saving}
            data-testid={getTestId("user_leftform__save_changes_btn")}
          >
            {id ? t("shared.save_changes") : t("companies.create_user")}
          </Button>
        </Affix>
      </Row>
    </FormCard>
  )

  return id && (loading || isLoadingPortfolios) ? (
    <FormCard
      subtitle={t("companies.info")}
      title={t("shared.admin_console")}
      testId={getTestId("user_leftform__card")}
    >
      <Affix className="rxd-auth-absolute-center">
        <Spin data-testid={getTestId("user_leftform__spin")} />
      </Affix>
    </FormCard>
  ) : (
    mainForm()
  )
}

export default LeftForm
