import React from "react"
import { useTranslation } from "react-i18next"
import { Affix, Button, Col, Divider, Input, Row, Select, Spin, Typography } from "antd"
import FormItemLabel from "antd/lib/form/FormItemLabel"
import { Company } from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import FormCard from "components/Admin/FormCard"
import ThinFormItem from "components/Antd/ThinFormItem"
import { Spacing } from "constants/shared"
import { getLanguageOptions } from "functions/Form"

const { Option } = Select

interface LeftFormProps {
  loading: boolean
  company?: Company
  id?: string
  saving: boolean
}

const LeftForm: React.FC<LeftFormProps> = ({ loading, company, id, saving }) => {
  const { t } = useTranslation("auth")

  const getMoreInfo = () => (
    <>
      <Row>
        <Divider />
      </Row>
      <Row>
        <Col span={23}>
          <Typography.Title level={4}>{t("shared.more_info")}</Typography.Title>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <FormItemLabel label={t("companies.company_uuid")} prefixCls="" />
        </Col>
        <Col span={15} offset={1}>
          <Typography.Text data-testid={getTestId("company-leftform-company-id-label")}>
            {company?.id}
          </Typography.Text>
        </Col>
      </Row>
    </>
  )

  const mainForm = () => (
    <FormCard
      subtitle={t("companies.info")}
      title={t("shared.admin_console")}
      data-testid={getTestId("company-leftform")}
    >
      <ThinFormItem
        name="name"
        label={t("companies.company_name")}
        rules={[{ required: true, message: t("companies.errors.company_name.required") }]}
        wrapperCol={{ span: 22 }}
        initialValue={company?.attributes.name}
      >
        <Input data-testid={getTestId("company-leftform-company-name-input")} />
      </ThinFormItem>
      <Row>
        <Col span={10}>
          <ThinFormItem
            name="internal_users_limit"
            label={t("companies.internal_users_limit")}
            rules={[
              { required: true, message: t("companies.errors.internal_users_limit.required") }
            ]}
            initialValue={company?.attributes.internal_users_limit}
          >
            <Input
              type="number"
              width={190}
              data-testid={getTestId("company-leftform-internal-users-limit-input")}
            />
          </ThinFormItem>
        </Col>
        <Col span={10} offset={2}>
          <ThinFormItem
            name="external_users_limit"
            label={t("companies.external_users_limit")}
            rules={[
              { required: true, message: t("companies.errors.external_users_limit.required") }
            ]}
            initialValue={company?.attributes.external_users_limit}
          >
            <Input
              type="number"
              width={190}
              data-testid={getTestId("company-leftform-external-users-limit-input")}
            />
          </ThinFormItem>
        </Col>
      </Row>
      <Row>
        <Col span={10}>
          <ThinFormItem
            name="language"
            label={t("shared.language")}
            rules={[{ required: true, message: t("companies.errors.language.required") }]}
            initialValue={company?.attributes.language}
          >
            <Select data-testid={getTestId("company-leftform-language-dropdown")}>
              {getLanguageOptions().map((langConfig) => (
                <Option key={langConfig.value} value={langConfig.value}>
                  {langConfig.label}
                </Option>
              ))}
            </Select>
          </ThinFormItem>
        </Col>
        <Col span={10} offset={2}>
          <ThinFormItem
            name="measured_by"
            rules={[{ required: true, message: t("shared.errors.measured_by.required") }]}
            label={t("shared.measured_by")}
            initialValue={company?.attributes.measured_by}
          >
            <Select
              disabled={!!company}
              data-testid={getTestId("company-leftform-measure-dropdown")}
            >
              <Option value="m2">{t("shared.measures.m2.text")}</Option>
              <Option value="f2">{t("shared.measures.f2.text")}</Option>
            </Select>
          </ThinFormItem>
        </Col>
      </Row>
      <Row>
        <Col span={10}>
          <ThinFormItem
            name="currency_code"
            rules={[{ required: true, message: t("shared.errors.currency_code.required") }]}
            label={t("shared.currency_code")}
            initialValue={company?.attributes.currency_code}
          >
            <Select
              disabled={!!company}
              data-testid={getTestId("company-leftform-currency-dropdown")}
            >
              <Option value="EUR">{t("shared.currencies.EUR.text")}</Option>
              <Option value="USD">{t("shared.currencies.USD.text")}</Option>
            </Select>
          </ThinFormItem>
        </Col>
        <Col span={10} offset={2}>
          <ThinFormItem
            name="quiqproperty_secret_key"
            label={t("companies.quiqproperty_secret_key")}
            initialValue={company?.attributes.quiqproperty_secret_key}
          >
            <Input
              width={190}
              data-testid={getTestId("company-leftform-quiqproperty_secret_key-input")}
            />
          </ThinFormItem>
        </Col>
      </Row>
      <Row>
        <Col span={10}>
          <ThinFormItem
            name="quiqproperty_public_key"
            label={t("companies.quiqproperty_public_key")}
            initialValue={company?.attributes.quiqproperty_public_key}
          >
            <Input
              width={190}
              data-testid={getTestId("company-leftform-quiqproperty_public_key-input")}
            />
          </ThinFormItem>
        </Col>
        <Col span={10} offset={2}>
          <ThinFormItem
            name="external_company_id"
            label={t("companies.external_company_id")}
            initialValue={company?.attributes.external_company_id}
          >
            <Input
              width={190}
              data-testid={getTestId("company-leftform-external_company_id-input")}
            />
          </ThinFormItem>
        </Col>
      </Row>
      <Row>
        <Col span={10}>
          <ThinFormItem
            name="cmm_organization_id"
            label={t("companies.cmm_organization_id")}
            initialValue={company?.attributes.cmm_organization_id}
          >
            <Input
              width={190}
              data-testid={getTestId("company-leftform-cmm_organization_id-input")}
            />
          </ThinFormItem>
        </Col>
      </Row>
      {id && !loading ? getMoreInfo() : null}
      <Row justify="end">
        <Affix style={{ position: "absolute", bottom: Spacing.s16px, right: Spacing.s16px }}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={saving}
            loading={saving}
            data-testid={getTestId("company-leftform--save-changes-btn")}
          >
            {id ? t("shared.save_changes") : t("companies.create_company")}
          </Button>
        </Affix>
      </Row>
    </FormCard>
  )

  return id && loading ? (
    <FormCard subtitle={t("companies.info")} title={t("shared.admin_console")}>
      <Affix className="rxd-auth-absolute-center">
        <Spin data-testid={getTestId("company-leftform-spin")} />
      </Affix>
    </FormCard>
  ) : (
    mainForm()
  )
}

export default LeftForm
