import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { FormInstance, Tabs } from "antd"
import { Company } from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { mapOldFeatureAccessToNewFeatureAccess } from "functions/Form"
import FormCard from "components/Admin/FormCard"
import Loading from "components/Admin/Loading/Loading"
import { CompanyForm } from "models/company-form.model"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import RequireAuth from "components/Admin/RequireAuth"
import { AuthContext } from "contexts/AuthContext"
import DynamicLayoutTab from "./DynamicLayoutTab"
import FeatureAccessTab from "./FeatureAccessTab"
import PortfoliosTab from "./PortfoliosTab"
import SensitivityTab from "./SensitivityTab"
import RentRollTab from "./RentRollTab"

const { TabPane } = Tabs

export interface RightFormProps {
  form: FormInstance<CompanyForm>
  company?: Company
  saving: boolean
  loading: boolean
}

const RightForm: React.FC<RightFormProps> = ({ form, company, saving, loading }) => {
  const { t } = useTranslation("auth")
  const { id } = useParams<{ id: string }>()
  const { isSuperAdmin } = useContext(AuthContext)

  return (
    <FormCard data-testid={getTestId("company-rightform")}>
      <Tabs defaultActiveKey="feature_access" data-testid={getTestId("company-rightform-tabs")}>
        <TabPane
          tab={t("shared.feature_accesses")}
          key="feature_access"
          data-testid={getTestId("company-rightform-tabs-feature-access-tab")}
        >
          <Loading loading={loading || saving}>
            <FeatureAccessTab
              form={form}
              featureAccess={
                mapOldFeatureAccessToNewFeatureAccess(company?.attributes?.feature_access) ??
                undefined
              }
              loading={loading}
              data-testid={getTestId("company-rightform-tabs-feature-access-content")}
            />
          </Loading>
        </TabPane>
        <TabPane
          tab={t("shared.dynamic_layout")}
          key="dynamic_layout"
          data-testid={getTestId("company-rightform-tabs-dynamic-layout-tab")}
        >
          <Loading loading={loading || saving}>
            <DynamicLayoutTab
              form={form}
              company={company}
              data-testid={getTestId("company-rightform-tabs-dynamic-layout-content")}
            />
          </Loading>
        </TabPane>
        {id && company && (
          <TabPane
            tab={t("shared.portfolios")}
            key="portfolios"
            data-testid={getTestId("company-rightform-tabs-portfolios-tab")}
            disabled={!isSuperAdmin()}
          >
            <Loading loading={loading || saving}>
              <RequireAuth minAccessRole={["superadmin"]}>
                <PortfoliosTab
                  company={company}
                  form={form}
                  data-testid={getTestId("company-rightform-tabs-portfolios-content")}
                />
              </RequireAuth>
            </Loading>
          </TabPane>
        )}
        <TabPane
          tab={t("shared.sensitivity")}
          key="sensitivity"
          data-testid={getTestId("company-rightform-tabs-sensitivity-tab")}
          disabled={!isSuperAdmin()}
        >
          <Loading loading={loading || saving}>
            <RequireAuth minAccessRole={["superadmin"]}>
              <SensitivityTab
                company={company}
                form={form}
                data-testid={getTestId("company-rightform-tabs-sensitivity-content")}
              />
            </RequireAuth>
          </Loading>
        </TabPane>
        <TabPane
          tab={t("companies.rent_roll.title")}
          key="rent_roll"
          data-testid={getTestId("company-rightform-tabs-rentroll-tab")}
        >
          <Loading loading={loading || saving}>
            <RequireAuth minAccessRole={["superadmin"]}>
              {!loading && (
                <RentRollTab
                  company={company}
                  form={form}
                  data-testid={getTestId("company-rightform-tabs-rentroll-content")}
                />
              )}
            </RequireAuth>
          </Loading>
        </TabPane>
      </Tabs>
    </FormCard>
  )
}

export default RightForm
