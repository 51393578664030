/* eslint-disable jsx-a11y/label-has-for */
import React, { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Affix, Col, FormInstance, Input, Row, Select, Space } from "antd"
import { Company, FeatureAccess, User } from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { sortAlfanumeric } from "@moodys/cre-cpm.functions.array"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import ThinFormItem from "components/Antd/ThinFormItem"
import { measured_by, Spacing } from "constants/shared"
import { AuthContext } from "contexts/AuthContext"
import getCurrencyCodesWithName from "functions/Currency"
import { isUserLocked } from "functions/User/Session"
import { UserForm } from "models/user-form.model"
import { getLanguageOptions } from "functions/Form"

const { Option } = Select

export interface BasicUserFormProps {
  form: FormInstance<UserForm>
  user?: User
  id?: string
  sendInviteOrResetPasswordButton?: JSX.Element
  lockUserCheckbox?: JSX.Element
  selectedCompany?: Company
  userFeatureAccess?: FeatureAccess[]
}

const BasicUserForm: React.FC<BasicUserFormProps> = ({
  form,
  user,
  id,
  sendInviteOrResetPasswordButton,
  lockUserCheckbox,
  selectedCompany,
  userFeatureAccess
}) => {
  const { t } = useTranslation("auth")
  const { hasAnyFeatureAccess, userType } = useContext(AuthContext)

  useEffect(() => {
    if (form && !id && selectedCompany) {
      form.setFieldsValue({
        currency_code: selectedCompany.attributes?.currency_code,
        measured_by: selectedCompany.attributes?.measured_by
      })
    }
    // eslint-disable-next-line
  }, [selectedCompany])

  const shouldDisableFeaturedFields = () => {
    if (userType === "superadmin") return false
    if (userType === "company_user" && hasAnyFeatureAccess(["unit_conversions"])) {
      return false
    }
    return !userFeatureAccess?.includes("unit_conversions")
  }

  return (
    <>
      <Row>
        <Col span={11}>
          <ThinFormItem
            name="name"
            label={t("users.first_name")}
            rules={[{ required: true, message: t("users.errors.first_name.required") }]}
            initialValue={user?.attributes?.name}
          >
            <Input data-testid={getTestId("user_leftform__first_name_input")} />
          </ThinFormItem>
        </Col>
        <Col span={11} offset={1}>
          <ThinFormItem
            name="family_name"
            label={t("users.family_name")}
            rules={[{ required: true, message: t("users.errors.family_name.required") }]}
            initialValue={user?.attributes?.family_name}
          >
            <Input data-testid={getTestId("user_leftform__family_name_input")} />
          </ThinFormItem>
        </Col>
      </Row>
      <Row>
        <Col span={id ? 14 : 23}>
          <ThinFormItem
            name="email"
            label={t("users.email")}
            rules={[
              { required: true, message: t("users.errors.email.required") },
              { type: "email", message: t("shared.errors.invalidFormat") }
            ]}
            initialValue={user?.attributes?.email}
          >
            <Input data-testid={getTestId("user_leftform__email_input")} disabled={!!id} />
          </ThinFormItem>
        </Col>
        {id && sendInviteOrResetPasswordButton && (
          <Col span={5} offset={1}>
            <ThinFormItem label=" ">{sendInviteOrResetPasswordButton}</ThinFormItem>
          </Col>
        )}
      </Row>
      <Row>
        <Col span={14}>
          <ThinFormItem
            name="language"
            label={t("shared.language")}
            rules={[{ required: true, message: t("users.errors.language.required") }]}
            initialValue={user?.attributes?.language}
          >
            <Select data-testid={getTestId("user_leftform__language_dropdown")}>
              {getLanguageOptions().map((langConfig) => (
                <Option key={langConfig.value} value={langConfig.value}>
                  {langConfig.label}
                </Option>
              ))}
            </Select>
          </ThinFormItem>
        </Col>
        <Col span={8} offset={1} style={{ display: "flex", marginTop: Spacing.s16px }}>
          {lockUserCheckbox && (
            <Space direction="horizontal">
              <ThinFormItem
                name="locked_at"
                initialValue={isUserLocked(user?.attributes?.locked_at)}
                valuePropName="checked"
                style={{ marginBottom: 0 }}
              >
                {lockUserCheckbox}
              </ThinFormItem>
              <Affix style={{ float: "right" }}>
                <label htmlFor="locked_at">{t("users.lock_at")}</label>
              </Affix>
            </Space>
          )}
        </Col>
      </Row>
      {id && (
        <Row>
          <Col span={14}>
            <ThinFormItem
              name="currency_code"
              label={t("shared.currency_code")}
              rules={[{ required: true, message: t("shared.errors.currency_code.required") }]}
              initialValue={user?.attributes?.currency_code}
            >
              <Select
                data-testid={getTestId("user_leftform__currency_code_dropdown")}
                disabled={shouldDisableFeaturedFields()}
              >
                {getCurrencyCodesWithName()
                  .sort((valueA, valueB) => sortAlfanumeric(valueA.name, valueB.name))
                  .map((currency) => (
                    <Option value={currency.currency}>{currency.name}</Option>
                  ))}
              </Select>
            </ThinFormItem>
          </Col>
          <Col span={8} offset={1}>
            <ThinFormItem
              name="measured_by"
              label={t("shared.measured_by")}
              rules={[{ required: true, message: t("shared.errors.measured_by.required") }]}
              initialValue={user?.attributes?.measured_by}
            >
              <Select
                data-testid={getTestId("user_leftform__measured_by_dropdown")}
                disabled={shouldDisableFeaturedFields()}
              >
                {measured_by.map((measure) => (
                  <Option value={measure}>{t(`shared.measures.${measure}.text`)}</Option>
                ))}
              </Select>
            </ThinFormItem>
          </Col>
        </Row>
      )}
    </>
  )
}

export default BasicUserForm
