import React, { useContext, useEffect, useState } from "react"
import { Button, Checkbox, Col, Form, Input, Row, Typography } from "antd"
import { useTranslation } from "react-i18next"
import Container from "components/Container"
import { AppContext } from "contexts/AppContext"
import AuthCard from "components/AuthCard"
import { AuthContext } from "contexts/AuthContext"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import "./index.less"
import { Routes, mailToHelp } from "constants/shared"

const Login: React.FC = () => {
  const { t } = useTranslation("auth")
  const { login, sso } = useContext(AuthContext)
  const { callback } = useContext(AppContext)
  const [remember, setRemember] = useState<boolean>(false)

  const onFinish = ({ email, password }: { email: string; password: string }) => {
    login(email, password, remember, callback)
  }

  useEffect(() => {
    if (sso) {
      sso?.tokenManager.clear()
      sso?.stop()
    }
  })

  return (
    <Container>
      <AuthCard>
        <Row>
          <Col span={24}>
            <Form
              layout="vertical"
              onFinish={onFinish}
              requiredMark={false}
              style={{ width: "100%" }}
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={t("login.email")}
                    name="email"
                    className="email"
                    rules={[
                      { type: "email", message: t("shared.errors.invalidFormat") },
                      { required: true, message: t("shared.errors.fieldRequired") }
                    ]}
                  >
                    <Input
                      className="user-email-input"
                      size="large"
                      data-testid={getTestId("login__email_input")}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={t("login.password")}
                    name="password"
                    className="password"
                    rules={[{ required: true, message: t("shared.errors.fieldRequired") }]}
                  >
                    <Input.Password
                      className="user-password-input"
                      size="large"
                      data-testid={getTestId("login__pwd_input")}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="space-between">
                <Col span={12}>
                  <Form.Item name="remember" className="remember">
                    <Checkbox
                      onChange={(event) => {
                        setRemember(event.target.checked)
                      }}
                      data-testid={getTestId("login__remember_me_checkbox")}
                      checked={remember}
                    >
                      {t("login.rememberMe")}
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Button
                    style={{ float: "right" }}
                    type="link"
                    htmlType="button"
                    className="forgot-password"
                    data-testid={getTestId("login__forgot_pwd_link")}
                    href={Routes.passwordRecovery}
                  >
                    {t("login.forgotPassword")}
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="large"
                      className="login-button btn-brand"
                      style={{ width: "100%" }}
                      data-testid={getTestId("login__submit_btn")}
                    >
                      {t("login.signinButton")}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Typography.Link
                    target="_blank"
                    href={mailToHelp}
                    rel="noreferrer"
                    className="link-brand"
                  >
                    {t("shared.help")}
                  </Typography.Link>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </AuthCard>
    </Container>
  )
}

export default Login
