import moment from "moment"
import {
  Company,
  CompanyAttributeNames,
  FeatureAccess,
  User,
  UserAttributesNames,
  UserType,
  DynamicLayout
} from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { HttpVerb } from "constants/shared"
import initialCompany from "contexts/initializers/company"
import initialUser from "contexts/initializers/user"
import { CompanyForm } from "models/company-form.model"
import { UserForm } from "models/user-form.model"
import { AllKey } from "pages/Admin/Users/Edit/function"
import authI18n from "functions/I18n"

const isNotAllowedOnPut = ["measured_by", "currency_code"]

const setupExpirationDate = (expirationDate: moment.Moment) =>
  expirationDate.hour(23).minute(59).second(59).millisecond(0).unix()

// **** START Compatibility layer with new features renamed ****
// ews => ews_edge and map_research => maket_data
// Also as TODO we should export the client api attribute interfaces from company attributes (missing currently)
// and use of keyof to avoid some type warnings
// Also security checks to avoid scalation should be done in API not here
export const mapOldFeatureAccessToNewFeatureAccess = (
  featureAccess?: FeatureAccess[]
): FeatureAccess[] | undefined => {
  if (!featureAccess) {
    return featureAccess
  }
  let newFeatureAccess = [...featureAccess]
  if (newFeatureAccess.includes("map_research") && !newFeatureAccess.includes("market_data")) {
    newFeatureAccess = newFeatureAccess.concat(["market_data"])
  }
  if (newFeatureAccess.includes("ews") && !newFeatureAccess.includes("news_edge")) {
    newFeatureAccess = newFeatureAccess.concat(["news_edge"])
  }
  return newFeatureAccess
}

export const mapNewFeatureAccessToOldFeatureAccess = (
  features?: FeatureAccess[]
): FeatureAccess[] => {
  let withOldFeatureAccess = [...(features ?? [])]
  if (withOldFeatureAccess.includes("news_edge") && !withOldFeatureAccess.includes("ews")) {
    withOldFeatureAccess = withOldFeatureAccess.concat(["ews"])
  }
  if (
    withOldFeatureAccess.includes("market_data") &&
    !withOldFeatureAccess.includes("map_research")
  ) {
    withOldFeatureAccess = withOldFeatureAccess.concat(["map_research"])
  }
  if (!withOldFeatureAccess.includes("news_edge") && withOldFeatureAccess.includes("ews")) {
    withOldFeatureAccess = withOldFeatureAccess.filter((oldFeature) => oldFeature !== "ews")
  }
  if (
    !withOldFeatureAccess.includes("market_data") &&
    withOldFeatureAccess.includes("map_research")
  ) {
    withOldFeatureAccess = withOldFeatureAccess.filter(
      (oldFeature) => oldFeature !== "map_research"
    )
  }
  return withOldFeatureAccess
}

// **** END Compatibility layer with new features renames ****

export const populateCompanyfromForm = (
  operation: HttpVerb,
  formData: CompanyForm,
  id?: string
): Company => {
  const company = {
    id,
    type: initialCompany.type,
    attributes: {}
  }

  const attributes: CompanyAttributeNames[] = Object.keys(
    initialCompany.attributes
  ) as CompanyAttributeNames[]

  attributes.forEach((attr: CompanyAttributeNames) => {
    const value = formData[attr]
    if (value !== null && value !== undefined) {
      if (attr === "layouts") {
        company.attributes[attr] = JSON.parse(value) as DynamicLayout
      } else if (attr === "loan_sensitivity_settings") {
        company.attributes[attr] = { ...value }
      } else if (attr === "feature_access") {
        company.attributes[attr] = mapNewFeatureAccessToOldFeatureAccess(formData.feature_access)
      } else if (
        operation === "POST" ||
        (operation === "PUT" && !isNotAllowedOnPut.includes(attr))
      ) {
        company.attributes[attr] = value
      }
    }
  })

  return company as Company
}

export const populateUserFromForm = (
  formData: UserForm,
  ownUserType: UserType,
  ownCompany: string,
  hasAnyFeatureAccess: (featureAccess: FeatureAccess[]) => boolean,
  id?: number
): User => {
  const user = {
    id,
    type: initialUser.type,
    attributes: {},
    relationships: {
      company: {
        // TODO: consistance, we should have company or companies, just one of them for GET and PUT/POST
        type: initialCompany.type,
        attributes: {
          company_ref_uuid: ""
        }
      }
    }
  }
  const userType = !formData.user_type ? ownUserType : formData.user_type

  // ToDo: Use UsetType enum instead of string. Ticket: https://moodys-cre.atlassian.net/browse/MPDI-3736
  const isScalatingSuperadminPrivilegies = userType === "superadmin" && ownUserType !== "superadmin"
  const isScalatingCompanyAdminPrivilegies =
    userType === "company_admin" && ownUserType !== "company_admin" && ownUserType !== "superadmin"
  const isScalatingToAdminPrivilegies =
    ownUserType !== "superadmin" && ownUserType !== "company_admin" && userType !== ownUserType

  const isScalatingPrivilegies =
    // Just superadmin could setup superadmin roles
    isScalatingSuperadminPrivilegies ||
    // Just admins (company and super) could setup company admins
    isScalatingCompanyAdminPrivilegies ||
    // Non admin users just can setup its own role
    isScalatingToAdminPrivilegies

  // API will reset this field when user type is non external
  const shouldDeleteExternalExpirationDateField = (attr: UserAttributesNames): boolean =>
    userType !== "external_user" && attr === "expiration_date"

  const shouldNotIncludeEmail = (attr: UserAttributesNames): boolean => !!id && attr === "email"

  const shouldNotIncludeWithoutFeatureAccess = (attr: UserAttributesNames): boolean =>
    (ownUserType === "company_user" || ownUserType === "external_user") &&
    !hasAnyFeatureAccess(["unit_conversions"]) &&
    (attr === "currency_code" || attr === "measured_by")

  const shouldNotIncludeFeatureAccessForInternalUser = (attr: UserAttributesNames): boolean =>
    !formData?.feature_access && ownUserType === "company_user" && attr === "feature_access"

  const attributes = Object.keys(initialUser.attributes) as UserAttributesNames[]

  // When add airbrake sent to handleError to register this
  // BCK should always check this
  if (isScalatingPrivilegies) throw new Error("Your role can not scalate over your own role")

  attributes.forEach((attr) => {
    if (
      shouldDeleteExternalExpirationDateField(attr) ||
      shouldNotIncludeEmail(attr) ||
      shouldNotIncludeWithoutFeatureAccess(attr) ||
      shouldNotIncludeFeatureAccessForInternalUser(attr)
    ) {
      user.attributes[attr] = undefined
    } else if (id && attr === "email") {
      user.attributes[attr] = undefined
    } else if (attr === "expiration_date" && userType === "external_user") {
      user.attributes[attr] = setupExpirationDate(formData.expiration_date)
    } else if (attr === "locked_at" && formData.locked_at !== undefined) {
      user.attributes[attr] = formData.locked_at ? moment().utc().unix() : null
    } else if (attr === "portfolios" && formData.portfolios !== undefined) {
      const normPortfolios = (formData.portfolios ?? []).includes(AllKey) ? [] : formData.portfolios
      user.attributes[attr] =
        ownUserType === "company_admin" || ownUserType === "superadmin"
          ? normPortfolios ?? []
          : undefined
    } else if (attr === "feature_access") {
      user.attributes[attr] = mapNewFeatureAccessToOldFeatureAccess(formData.feature_access)
    } else {
      user.attributes[attr] = formData[attr]
    }
  })

  user.relationships!.company.attributes.company_ref_uuid =
    ownUserType === "company_admin" || ownUserType === "company_user"
      ? ownCompany
      : formData.company_uuid ?? ownCompany

  return user as User
}

export const getLanguageOptions = () => [
  { value: "DE", label: authI18n.t("shared.languages.german.text") },
  { value: "EN", label: authI18n.t("shared.languages.english.text") },
  { value: "FR", label: authI18n.t("shared.languages.french.text") },
  { value: "ES", label: authI18n.t("shared.languages.spanish.text") }
]
