import React, { useMemo, useState } from "react"
import {
  authApi,
  Session,
  UserType,
  User,
  FeatureAccess
} from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { Language } from "@moodys/cre-cpm.models.localization"
import { ApiExceptionCodes } from "@moodys/cre-cpm-client-apis.apis.api-base"
import { getAuthCookie, saveAuthCookie } from "@moodys/cre-cpm.functions.cookies"
import { redirectTo, redirectToRoot } from "@moodys/cre-cpm.functions.window-location"
import { MapSSO } from "@moodys-ma-platform/auth-sdk"
import { isUsProdEnv } from "constants/shared"
import isPublicAccessView from "functions/WindowLocation"
import handleApiError from "functions/Exceptions"
import { logout as logoutHelper } from "functions/User/Session/index"
import platformApi from "api/services/platform"
import hasValidAppCallback from "functions/Security"
import SSO from "pages/Sso/Sso.class"

export interface AuthContextProps {
  isAuthenticated: boolean
  userType: UserType
  userId?: number
  isValidatingToken: boolean
  userCompanyUuid: string
  userCompanyRegion: string
  userLanguage: Language
  sso: MapSSO | null
  hasAnyFeatureAccess: (featureAccessToCheck?: FeatureAccess[]) => boolean
  isAdmin: () => boolean
  isSuperAdmin: () => boolean
  isCompanyAdmin: () => boolean
  login: (email: string, password: string, saveCookie: boolean, callback: string) => void
  logout: () => void
  ensureCookieSyncWithState: () => void
  hasLowerAdmPermissionsThan: (user: User) => boolean
  hasRole: (allowedRoles?: UserType[]) => boolean
  validateCredentials: () => void
}

export interface AuthContextProviderProps {
  children: React.ReactNode
}

export const initialAuthContext: AuthContextProps = {
  isAuthenticated: false,
  userType: "unknown",
  isValidatingToken: true,
  userLanguage: "en",
  userCompanyUuid: "",
  userCompanyRegion: "",
  sso: null,
  hasAnyFeatureAccess: () => false,
  hasRole: () => false,
  isAdmin: () => false,
  isSuperAdmin: () => false,
  isCompanyAdmin: () => false,
  login: () => {},
  logout: () => {},
  ensureCookieSyncWithState: () => {},
  hasLowerAdmPermissionsThan: () => false,
  validateCredentials: () => {}
}

export const AuthContext = React.createContext(initialAuthContext)

export const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const [userType, setUserType] = useState<UserType>("unknown")
  const [userId, setUserId] = useState<number | undefined>(undefined)
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [isValidatingToken, setIsValidatingToken] = useState<boolean>(true)
  const [token, updateToken] = useState<string | undefined>(undefined)
  const [userCompanyUuid, setUserCompanyUuid] = useState<string>("")
  const [userCompanyRegion, setUserCompanyRegion] = useState<string>("")
  const [userLanguage, setUserLanguage] = useState<Language>("en")
  const [featureAccess, setFeatureAccess] = useState<FeatureAccess[]>([])
  const [sso] = useState(SSO.getInstance())

  const isSuperAdmin = (): boolean => ["superadmin"].includes(userType)

  const isAdmin = (): boolean => ["superadmin", "company_admin"].includes(userType)

  const isCompanyAdmin = (): boolean => userType === "company_admin"

  const hasLowerAdmPermissionsThan = (user: User) =>
    userType !== "superadmin" && user.attributes.user_type === "superadmin"

  const hasAnyFeatureAccess = (featuresToCheck?: FeatureAccess[]): boolean =>
    !featuresToCheck ||
    featuresToCheck?.length === 0 ||
    featureAccess.some((featureAcces: FeatureAccess) => featuresToCheck.includes(featureAcces))

  const hasRole = (rolesToCheck?: UserType[]): boolean =>
    !rolesToCheck || rolesToCheck?.length === 0 || rolesToCheck.includes(userType)

  const setupAuthUserState = (session: Session) => {
    if (session.attributes.user && session.attributes.user.user_type) {
      setUserType(session.attributes.user.user_type)
      setUserId(session.attributes.user.user_id)
      setUserCompanyRegion(session.attributes.company?.region ?? "")
      setUserCompanyUuid(session.attributes.company?.company_ref_uuid ?? "")
      setUserLanguage(session.attributes.user?.language ?? "en")
      setFeatureAccess(session.attributes?.feature_access ?? [])
      return true
    }
    return false
  }

  const validateCredentials = (): void => {
    setIsValidatingToken(true)
    setIsAuthenticated(false)
    const rxdAuthSession = getAuthCookie()

    if (rxdAuthSession && !isPublicAccessView()) {
      authApi
        .getSession()
        .then((result) => {
          if (result.kind === "ok") {
            const sucessSetup = setupAuthUserState(result.data)
            setIsAuthenticated(sucessSetup)
            if (sucessSetup) {
              updateToken(rxdAuthSession)
            }
          }
        })
        .catch((err) => {
          handleApiError(err)
        })
        .finally(() => {
          setIsValidatingToken(false)
        })
    } else {
      setIsValidatingToken(false)
    }
  }

  const login = (email: string, password: string, saveCookie: boolean, callback: string) => {
    if (!hasValidAppCallback(callback)) {
      logoutHelper(sso, false)
      return
    }
    authApi
      .postUserSession(email, password)
      .then((response) => {
        if (response.kind === "ok") {
          authApi.setToken(response.data.attributes.token)
          platformApi.setToken(response.data.attributes.token)
          saveAuthCookie(saveCookie, response.data.attributes.token)
          updateToken(response.data.attributes.token)
          redirectTo(callback || process.env.REACT_APP_REDIRECT_URL || "")
        } else if (response.kind === "not-found") {
          setIsAuthenticated(false)
          logoutHelper(sso, false)
          handleApiError("unauthorized")
        } else if (response.kind === ApiExceptionCodes.unauthorized) {
          setIsAuthenticated(false)
          handleApiError("unauthorized")
        } else {
          setIsAuthenticated(false)
          logoutHelper(sso, false)
          handleApiError(response)
        }
      })
      .catch((error) => {
        setIsAuthenticated(false)
        logoutHelper(sso, false)
        if (error.response && error.response.status === 401) {
          handleApiError("unauthorized")
        } else {
          handleApiError("unknown")
        }
      })
  }

  const logout = () => {
    logoutHelper(sso, !isUsProdEnv)
  }

  const ensureCookieSyncWithState = (): void => {
    if (!isAuthenticated) return
    const rxdAuthSession = getAuthCookie()
    if (!rxdAuthSession) redirectToRoot()
    if (token && token !== rxdAuthSession) {
      validateCredentials()
    }
  }

  const value = useMemo(
    () => ({
      isAdmin,
      isSuperAdmin,
      isCompanyAdmin,
      userType,
      userId,
      userLanguage,
      isAuthenticated,
      isValidatingToken,
      hasAnyFeatureAccess,
      hasRole,
      login,
      logout,
      ensureCookieSyncWithState,
      userCompanyUuid,
      userCompanyRegion,
      hasLowerAdmPermissionsThan,
      validateCredentials,
      sso
    }),
    [userType, userId, userCompanyUuid, userLanguage, isAuthenticated, isValidatingToken]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
