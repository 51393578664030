import loanSensitivityRanges, { loanSensitivityDefaults } from "constants/loan-sensitivity"
import React, { useState, useEffect } from "react"
import clsx from "clsx"
import "./index.less"
import { Row, Col, InputNumber, Typography } from "antd"
import { faCaretUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation } from "react-i18next"
import { LoanSensitivitySettingsRange } from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { getStep, getTitle, parseInputMax, parseInputMin } from "./functions"
import { SensitivityBarMode } from ".."

const { Title } = Typography

export interface SensitivityBarProps {
  mode: SensitivityBarMode
  range?: LoanSensitivitySettingsRange
  onChange: (range: LoanSensitivitySettingsRange) => void
}

type NumericInputType = "lower" | "upper"

const SensitivityBar: React.FC<SensitivityBarProps> = ({ mode, range, onChange }) => {
  const { t } = useTranslation("auth")
  const [currentRange, setCurrentRange] = useState(range ?? loanSensitivityDefaults[mode])
  const handleChange = (type: NumericInputType) => (value: number | null) => {
    const newRange = { ...currentRange, [type]: value }
    setCurrentRange(newRange)
  }
  const afterInputContent = mode === "dscr" ? undefined : "%"
  useEffect(() => {
    onChange(currentRange)
  }, [currentRange])

  const isLTVmode = mode === "ltv"
  return (
    <>
      <Title level={5}>{getTitle(mode, t)}</Title>
      <Row className="cpm-auth-sensitivity-bar">
        <Col
          span={2}
          className={`cpm-auth-sensitivity-bar__step cpm-auth-sensitivity-bar__step${
            isLTVmode ? "--maximum-3" : "--minimum-1"
          }`}
        />
        <Col
          span={2}
          className={`cpm-auth-sensitivity-bar__step cpm-auth-sensitivity-bar__step${
            isLTVmode ? "--maximum-2" : "--minimum-2"
          }`}
        />
        <Col
          span={2}
          className={`cpm-auth-sensitivity-bar__step cpm-auth-sensitivity-bar__step${
            isLTVmode ? "--maximum-1" : "--minimum-3"
          }`}
        />

        <Col
          span={2}
          className={`cpm-auth-sensitivity-bar__step cpm-auth-sensitivity-bar__step${
            isLTVmode ? "--middle-3" : "--middle-1"
          }`}
        >
          <FontAwesomeIcon
            icon={faCaretUp}
            className="cpm-auth-sensitivity-bar__step__icon cpm-auth-sensitivity-bar__step__icon--left"
          />
          <div
            className={clsx(
              "cpm-auth-sensitivity-bar__step__input",
              "cpm-auth-sensitivity-bar__step__input--left",
              mode === "dscr" && "cpm-auth-sensitivity-bar__step__input--small"
            )}
          >
            <InputNumber
              step={getStep(mode)}
              size="small"
              addonBefore="<"
              addonAfter={afterInputContent}
              defaultValue={range?.lower ?? loanSensitivityDefaults[mode].lower}
              min={loanSensitivityRanges[mode].lower}
              max={loanSensitivityRanges[mode].upper}
              parser={parseInputMin(mode)}
              onChange={handleChange("lower")}
            />
          </div>
        </Col>
        <Col
          span={2}
          className="cpm-auth-sensitivity-bar__step cpm-auth-sensitivity-bar__step--middle-2"
        />
        <Col
          span={2}
          className={`cpm-auth-sensitivity-bar__step cpm-auth-sensitivity-bar__step${
            isLTVmode ? "--middle-1" : "--middle-3"
          }`}
        >
          <FontAwesomeIcon
            icon={faCaretUp}
            className="cpm-auth-sensitivity-bar__step__icon cpm-auth-sensitivity-bar__step__icon--right"
          />
          <div
            className={clsx(
              "cpm-auth-sensitivity-bar__step__input",
              "cpm-auth-sensitivity-bar__step__input--right",
              mode === "dscr" && "cpm-auth-sensitivity-bar__step__input--small"
            )}
          >
            <InputNumber
              step={getStep(mode)}
              size="small"
              addonBefore=">"
              addonAfter={afterInputContent}
              defaultValue={range?.upper ?? loanSensitivityDefaults[mode].upper}
              min={loanSensitivityRanges[mode].lower}
              max={loanSensitivityRanges[mode].upper}
              parser={parseInputMax(mode)}
              onChange={handleChange("upper")}
            />
          </div>
        </Col>
        <Col
          span={2}
          className={`cpm-auth-sensitivity-bar__step cpm-auth-sensitivity-bar__step${
            isLTVmode ? "--minimum-3" : "--maximum-1"
          }`}
        />
        <Col
          span={2}
          className={`cpm-auth-sensitivity-bar__step cpm-auth-sensitivity-bar__step${
            isLTVmode ? "--minimum-2" : "--maximum-2"
          }`}
        />
        <Col
          span={2}
          className={`cpm-auth-sensitivity-bar__step cpm-auth-sensitivity-bar__step${
            isLTVmode ? "--minimum-1" : "--maximum-3"
          }`}
        />
      </Row>
    </>
  )
}

export default React.memo(SensitivityBar)
