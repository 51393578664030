import React, { Suspense } from "react"
import { I18nextProvider } from "react-i18next"
import { LicenseManager } from "ag-grid-enterprise"
import "./App.less"
import Routes from "Routes"
import AppBootstrap from "components/AppBootstrap"
import { BrowserRouter } from "react-router-dom"
import { AuthContextProvider } from "contexts/AuthContext"
import { AppContextProvider } from "contexts/AppContext"
import { ThemeProvider } from "@moodys/cre-cpm-platform-shared.contexts.theme-provider"
import authI18n from "functions/I18n"
import { Spin } from "antd"

LicenseManager.setLicenseKey(process.env.REACT_APP_AGGRID_KEY || "")

const App: React.FC = () => (
  <I18nextProvider i18n={authI18n}>
    <ThemeProvider prefixCls="ant5-auth">
      <AuthContextProvider>
        <Suspense fallback={<Spin />}>
          <AppContextProvider>
            <AppBootstrap>
              <BrowserRouter>
                <Routes />
              </BrowserRouter>
            </AppBootstrap>
          </AppContextProvider>
        </Suspense>
      </AuthContextProvider>
    </ThemeProvider>
  </I18nextProvider>
)

export default App
