import { User, UserType } from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { Language } from "@moodys/cre-cpm.models.localization"

const initialUser: User = Object.freeze({
  type: "users",
  id: undefined,
  attributes: {
    email: "",
    password: "",
    user_type: "superadmin" as UserType,
    last_sign_in_at: 0,
    portfolios: [],
    name: "",
    family_name: "",
    language: "en" as Language,
    active: true,
    external_organization: "",
    expiration_date: 0,
    feature_access: [],
    locked_at: null,
    measured_by: "m2",
    currency_code: "USD"
  },
  relationships: {
    company: undefined
  }
})

export default initialUser
